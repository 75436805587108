import Vue from "vue";
import Router from "vue-router";
import appConfig from "../../src/app.config";
import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("./views/home"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/login",
      component: () => import("./views/login"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/logout",
      redirect: "/login",
    },
    {
      path: "/register",
      component: () => import("./views/register"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/account",
      component: () => import("./views/account/account"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/account/documents",
      component: () => import("./views/account/documents"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/account/membership",
      component: () => import("./views/account/membership"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/account/address",
      component: () => import("./views/account/address"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/account/password",
      component: () => import("./views/account/password"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/account/password/security",
      component: () => import("./views/account/password-security"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/account/password/security/:token",
      component: () => import("./views/account/password-security"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/account/bank",
      component: () => import("./views/account/bank"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/account/pix",
      component: () => import("./views/account/pix"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/wallet/extract",
      component: () => import("./views/wallet/extract-network"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/wallet/transfer",
      component: () => import("./views/wallet/transfer"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/wallet/pay",
      component: () => import("./views/wallet/pay"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/wallet/pay/:id",
      component: () => import("./views/wallet/pay"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/wallet/withdrawal",
      component: () => import("./views/wallet/withdrawal"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/wallet/withdrawal/history",
      component: () => import("./views/wallet/withdrawal-history"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/network/clients",
      component: () => import("./views/network/clients"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/network/sponsored",
      component: () => import("./views/network/sponsored"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/network/team",
      component: () => import("./views/network/team"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/network/matriz",
      component: () => import("./views/network/matriz"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/network/matriz/:id",
      component: () => import("./views/network/matriz"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/network/unilevel",
      component: () => import("./views/network/unilevel"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/network/unilevel/:id",
      component: () => import("./views/network/unilevel"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/reports/indicacao",
      component: () => import("./views/reports/indicacao"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/reports/desempenho",
      component: () => import("./views/reports/desempenho"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/reports/vendas",
      component: () => import("./views/reports/vendas"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/reports/graduation",
      component: () => import("./views/reports/graduation"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/store",
      redirect: "/store/featured",
    },
    {
      path: "/store/featured",
      component: () => import("./views/store/featured"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/store/category/:id",
      component: () => import("./views/store/category"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/store/search",
      component: () => import("./views/store/search"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/store/cart",
      component: () => import("./views/store/cart"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/store/orders",
      component: () => import("./views/store/orders"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/store/order/:id",
      component: () => import("./views/store/order"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/downloads",
      component: () => import("./views/downloads"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/faq",
      component: () => import("./views/faq"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/tickets",
      component: () => import("./views/tickets"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/tickets/:id",
      component: () => import("./views/tickets-view"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/courses",
      component: () => import("./views/courses/list"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/courses/:course",
      component: () => import("./views/courses/view"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/courses/:course/:module/:lesson",
      component: () => import("./views/courses/view"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/404",
      component: () => import("./views/404"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "*",
      redirect: "/register*",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          if (
            response.data.order &&
            response.data.order.id &&
            to.path != "/store/orders"
          ) {
            return next("/store/order/" + response.data.order.id);
          } else if (
            response.data.user.membership &&
            response.data.user.membership.status &&
            response.data.user.membership.status == 'pending' &&
            response.data.user.membership.order &&
            response.data.user.membership.order.id
          ) {
            return next("/store/order/" + response.data.user.membership.order.id);
          }
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  }

  document.title = to.meta.title;
  next();
});

export default router;
